import React from 'react';
import * as Styled from './slpGreyLogoFooterStyle';
import { BLOCKS } from '@contentful/rich-text-types';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const SlpGreyLogoFooter = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          <Styled.ImageStyle src={asset.node.file.url} alt={asset.node.title} />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.FooterContainer>
      <Styled.FootStyle>
        {sectionData?.richText && (
          <>
            {documentToReactComponents(
              JSON.parse(sectionData?.richText?.raw),
              optionsMainStyle
            )}
          </>
        )}
      </Styled.FootStyle>
    </Styled.FooterContainer>
  );
};
export default SlpGreyLogoFooter;
